<template>
  <mobile-screen :header="true" screen-class="change-password-screen icon-app1">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="icon-hea1">
        <template v-slot:left>
          <router-link :to="{ name: backLinkName || 'r_profile' }">
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName("profile", "change-password", "change-password")
          }}
        </div>
        <template v-slot:right>
          <button @click="initSubmit">
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <VeeForm
      @submit="submitForm"
      v-slot="{ errors }"
      class="form tiny-input change-password-form no-borders"
      novalidate
    >
      <ul class="clebex-item-section">
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.current_password }"
        >
          <span class="error-message" v-if="errors.current_password">
            {{ errors.current_password }}
          </span>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="firstNameId"
                >{{
                  displayLabelName(
                    "profile",
                    "change-password",
                    "current-password"
                  )
                }}
                <span>*</span></label
              >
              <Field
                name="current_password"
                as="input"
                type="password"
                rules="required|min:6"
                :disabled="submitting"
              />
            </div>
          </div>
        </li>
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.password }"
        >
          <span class="error-message" v-if="errors.password">
            {{ errors.password }}
          </span>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="firstNameId"
                >{{
                  displayLabelName("profile", "change-password", "new-password")
                }}
                <span>*</span></label
              >
              <Field
                name="password"
                as="input"
                type="password"
                rules="required|min:6"
                :disabled="submitting"
              />
            </div>
          </div>
        </li>
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.password_confirmation }"
        >
          <span class="error-message" v-if="errors.password_confirmation">
            {{ errors.password_confirmation }}
          </span>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="firstNameId"
                >{{
                  displayLabelName(
                    "profile",
                    "change-password",
                    "confirm-new-password"
                  )
                }}
                <span>*</span></label
              >
              <Field
                name="password_confirmation"
                as="input"
                type="password"
                rules="required|confirmed:@password"
                :disabled="submitting"
              />
            </div>
          </div>
        </li>
      </ul>
      <button type="submit" style="display: none;" ref="submitFormBtn">
        Submit form
      </button>
    </VeeForm>
  </mobile-screen>
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import httpServiceAuth, {
  getSubdomain,
  getUserId
} from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { mapState } from "vuex";
import { errorHandler } from "@/services/error-handler";
export default {
  name: "ChangePassword",
  data() {
    return {
      submitting: false
    };
  },
  computed: {
    ...mapState("user", ["userData"])
  },
  methods: {
    submitForm(values, { resetForm }) {
      if (values) {
        let userId = getUserId();
        if (this.$route.params && this.$route.params.user_id) {
          userId = this.$route.params.user_id;
        }
        const subdomain = getSubdomain();
        let url = `${apiEndpoints.master.users}/${userId}`;

        if (subdomain) {
          url = `${apiEndpoints.company.users}/${userId}`;
        }

        const data = {
          ...this.userData.data,
          ...values
        };

        this.submitting = true;
        this.$store.commit("loader/setScreenLoading", true, { root: true });
        httpServiceAuth
          .put(url, data)
          .then(() => {
            resetForm();
          })
          .catch(error => {
            if (error.response) {
              errorHandler(error.response, this.findElement());
            }
          })
          .finally(() => {
            this.submitting = false;
            this.$store.commit("loader/setScreenLoading", false, {
              root: true
            });
          });
      }
    },
    initSubmit() {
      const { submitFormBtn } = this.$refs;
      submitFormBtn.click();
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  props: {
    backLinkName: {
      type: String
    }
  }
};
</script>
